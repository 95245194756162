



































































import { Employee } from "@/models/entities/employee.interface";
import { Component, Prop, Vue } from "vue-property-decorator";

const CEmployeeCard = () =>
  import(
    /* webpackChunkName: "CEmployeeCard" */ "./components/CEmplyeeCard.vue"
  );

@Component({
  components: {
    "c-employee-card": CEmployeeCard,
  },
})
export default class CEmployees extends Vue {
  @Prop({ required: false, default: null, type: Number })
  private currentEmployeeId: number;
  @Prop({ required: false, default: null, type: Array })
  private employees: Employee[];

  get owners(): Employee[] {
    if (this.employees) {
      return this.employees.filter(
        (e) => e.employeeClassificationName.toLowerCase() == "titolare"
      );
    }
    return [] as Employee[];
  }

  get substitudes(): Employee[] {
    if (this.employees) {
      return this.employees.filter(
        (e) => e.employeeClassificationName.toLowerCase() == "sostituto"
      );
    }
    return [] as Employee[];
  }
}
